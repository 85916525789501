import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const lendingProgramProject: Project = {
  id: '',
  rootPath: '',
  name: 'Decentralized Lending Program',
  description: 'A Solana program that allows users to lend and borrow tokens. Lenders provide liquidity, and borrowers can take out loans against collateral.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-99887',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'Lending Program',
          item: {
            id: 'program-99887',
            type: 'program',
            name: 'Decentralized Lending Program',
            description: 'A decentralized platform for lending and borrowing tokens, with the option to pledge collateral for loans.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-99888',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'LoanAccount',
          item: {
            id: 'account-99888',
            type: 'account',
            name: 'LoanAccount',
            description: 'Stores loan details, including the lender, borrower, loan amount, collateral, and loan status.',
            json: '{lender: PubKey, borrower: PubKey, loan_amount: u64, collateral_amount: u64, interest_rate: f32, status: string}',
            ownerProgramId: 'program-99887',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-99889',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'LendTokens',
          item: {
            id: 'instruction-99889',
            type: 'instruction',
            name: 'LendTokens',
            description: 'Allows a user to lend tokens to a borrower, providing liquidity.',
            parameters: 'loan_account: AccountInfo, lender: PubKey, loan_amount: u64, collateral_amount: u64, interest_rate: f32',
            aiInstruction: 'Verify the lender’s balance, transfer the loan amount to the borrower, and store the collateral details. Set the loan status to "active."',
            ownerProgramId: 'program-99887',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-99890',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'BorrowTokens',
          item: {
            id: 'instruction-99890',
            type: 'instruction',
            name: 'BorrowTokens',
            description: 'Allows a user to borrow tokens, using collateral as security.',
            parameters: 'loan_account: AccountInfo, borrower: PubKey',
            aiInstruction: 'Ensure the borrower provides enough collateral, check the loan amount, and transfer the tokens to the borrower. Set the loan status to "active."',
            ownerProgramId: 'program-99887',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-99891',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'RepayLoan',
          item: {
            id: 'instruction-99891',
            type: 'instruction',
            name: 'RepayLoan',
            description: 'Allows a borrower to repay a loan with interest.',
            parameters: 'loan_account: AccountInfo, repayment_amount: u64, borrower: PubKey',
            aiInstruction: 'Verify the borrower’s repayment amount, check the loan balance, and update the loan status to "paid" once the loan is fully repaid.',
            ownerProgramId: 'program-99887',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-99892',
        type: 'instruction',
        position: { x: 300, y: 450 }, // Bottom-left
        data: {
          label: 'LiquidateLoan',
          item: {
            id: 'instruction-99892',
            type: 'instruction',
            name: 'LiquidateLoan',
            description: 'Allows liquidation of the loan if the borrower defaults.',
            parameters: 'loan_account: AccountInfo, borrower: PubKey',
            aiInstruction: 'Check if the loan is overdue or if the borrower has defaulted. Liquidate the collateral to cover the loan balance and set the loan status to "liquidated."',
            ownerProgramId: 'program-99887',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 450 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-99887',
        target: 'account-99888',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-99887',
        target: 'instruction-99889',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-99887',
        target: 'instruction-99890',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-99887',
        target: 'instruction-99891',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-5',
        source: 'program-99887',
        target: 'instruction-99892',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { lendingProgramProject };