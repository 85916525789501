import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const loanProgramProject: Project = {
  id: '',
  rootPath: '',
  name: 'Loan Program',
  description: 'A Solana program that manages a simple loan system, allowing users to borrow tokens with a collateral-backed loan structure.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-123456',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'Loan Program',
          item: {
            id: 'program-123456',
            type: 'program',
            name: 'Loan Program',
            description: 'Manages loans with collateral. Users can borrow tokens by providing collateral, and the loan can be repaid with interest.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-123457',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'LoanAccount',
          item: {
            id: 'account-123457',
            type: 'account',
            name: 'LoanAccount',
            description: 'Stores loan details including borrower, loan amount, collateral, and loan status.',
            json: '{borrower: PubKey, loan_amount: u64, collateral_amount: u64, interest_rate: f32, loan_status: string}',
            ownerProgramId: 'program-123456',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-123458',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'CreateLoan',
          item: {
            id: 'instruction-123458',
            type: 'instruction',
            name: 'CreateLoan',
            description: 'Creates a new loan by verifying the collateral and storing the loan details.',
            parameters: 'borrower: PubKey, loan_amount: u64, collateral_amount: u64, interest_rate: f32',
            aiInstruction: 'Verify the borrower, validate collateral, store loan details, and set loan status to "active". The loan should also include an interest rate, and collateral should exceed a minimum value.',
            ownerProgramId: 'program-123456',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-123459',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'RepayLoan',
          item: {
            id: 'instruction-123459',
            type: 'instruction',
            name: 'RepayLoan',
            description: 'Repays a portion of the loan, including interest, and reduces the loan balance.',
            parameters: 'loan_account: AccountInfo, payment_amount: u64, borrower: PubKey',
            aiInstruction: 'Verify the loan balance, calculate the repayment including interest, and update the loan balance accordingly. If the loan is fully repaid, mark the loan status as "paid off".',
            ownerProgramId: 'program-123456',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-123460',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'LiquidateLoan',
          item: {
            id: 'instruction-123460',
            type: 'instruction',
            name: 'LiquidateLoan',
            description: 'Liquidates the loan if the borrower defaults, seizing the collateral to cover the loan amount.',
            parameters: 'loan_account: AccountInfo, borrower: PubKey',
            aiInstruction: 'Check if the loan is overdue and if the borrower has defaulted. If so, liquidate the collateral and mark the loan status as "liquidated".',
            ownerProgramId: 'program-123456',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-123456',
        target: 'account-123457',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-123456',
        target: 'instruction-123458',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-123456',
        target: 'instruction-123459',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-123456',
        target: 'instruction-123460',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { loanProgramProject };