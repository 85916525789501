import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const crowdfundingProgramProject: Project = {
  id: '',
  rootPath: '',
  name: 'Crowdfunding Program',
  description: 'A Solana program that allows users to pledge funds towards a crowdfunding campaign. Once the campaign reaches its funding goal, the funds are released to the campaign owner.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-11223',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'Crowdfunding Program',
          item: {
            id: 'program-11223',
            type: 'program',
            name: 'Crowdfunding Program',
            description: 'Enables users to pledge funds to a crowdfunding campaign. Once the goal is reached, the funds are released to the campaign owner.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-11224',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'CampaignAccount',
          item: {
            id: 'account-11224',
            type: 'account',
            name: 'CampaignAccount',
            description: 'Stores campaign details including total funding goal, total pledged amount, and the list of backers.',
            json: '{owner: PubKey, goal_amount: u64, total_pledged: u64, backers: Vec<PubKey>, status: string}',
            ownerProgramId: 'program-11223',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-11225',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'PledgeFunds',
          item: {
            id: 'instruction-11225',
            type: 'instruction',
            name: 'PledgeFunds',
            description: 'Allows a user to pledge funds towards a crowdfunding campaign.',
            parameters: 'campaign_account: AccountInfo, pledge_amount: u64, backer: PubKey',
            aiInstruction: 'Check if the backer has sufficient funds, add the pledged amount to the total pledged amount, and update the backer list.',
            ownerProgramId: 'program-11223',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-11226',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'ReleaseFunds',
          item: {
            id: 'instruction-11226',
            type: 'instruction',
            name: 'ReleaseFunds',
            description: 'Releases the pledged funds to the campaign owner once the goal has been reached.',
            parameters: 'campaign_account: AccountInfo',
            aiInstruction: 'Check if the total pledged amount has reached the funding goal. If the goal is met, release the funds to the campaign owner and mark the campaign as successful.',
            ownerProgramId: 'program-11223',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-11227',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'RefundBackers',
          item: {
            id: 'instruction-11227',
            type: 'instruction',
            name: 'RefundBackers',
            description: 'Refunds all backers if the campaign does not reach its funding goal.',
            parameters: 'campaign_account: AccountInfo',
            aiInstruction: 'If the campaign fails to reach its goal by the end of the campaign period, refund all backers and mark the campaign as unsuccessful.',
            ownerProgramId: 'program-11223',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-11223',
        target: 'account-11224',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-11223',
        target: 'instruction-11225',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-11223',
        target: 'instruction-11226',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-11223',
        target: 'instruction-11227',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { crowdfundingProgramProject };