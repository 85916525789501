import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const nftMarketplaceProject: Project = {
  id: '',
  rootPath: '',
  name: 'NFT Marketplace',
  description: 'A Solana program that facilitates the buying and selling of NFTs in a marketplace. Users can list, purchase, and transfer NFTs.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-78901',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'NFT Marketplace',
          item: {
            id: 'program-78901',
            type: 'program',
            name: 'NFT Marketplace',
            description: 'Facilitates the buying, selling, and transfer of NFTs in a decentralized marketplace.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-78902',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'NFTAccount',
          item: {
            id: 'account-78902',
            type: 'account',
            name: 'NFTAccount',
            description: 'Stores the NFT metadata including owner, price, and transaction history.',
            json: '{owner: PubKey, price: u64, metadata: String, transaction_history: Vec<String>}',
            ownerProgramId: 'program-78901',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-78903',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'ListNFT',
          item: {
            id: 'instruction-78903',
            type: 'instruction',
            name: 'ListNFT',
            description: 'Lists an NFT for sale in the marketplace with a specified price.',
            parameters: 'nft_account: AccountInfo, price: u64',
            aiInstruction: 'Verify the NFT account, check that the NFT is owned by the user, and set the price for sale. Add the NFT to the marketplace listing.',
            ownerProgramId: 'program-78901',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-78904',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'BuyNFT',
          item: {
            id: 'instruction-78904',
            type: 'instruction',
            name: 'BuyNFT',
            description: 'Allows a user to purchase an NFT listed in the marketplace.',
            parameters: 'nft_account: AccountInfo, buyer: PubKey',
            aiInstruction: 'Verify that the NFT is listed for sale, ensure the buyer has sufficient funds, and transfer the NFT ownership to the buyer after deducting the price.',
            ownerProgramId: 'program-78901',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-78905',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'TransferNFT',
          item: {
            id: 'instruction-78905',
            type: 'instruction',
            name: 'TransferNFT',
            description: 'Transfers ownership of an NFT to another user without a monetary transaction.',
            parameters: 'nft_account: AccountInfo, new_owner: PubKey',
            aiInstruction: 'Transfer ownership of the NFT to the new user, updating the NFT account with the new owner’s public key.',
            ownerProgramId: 'program-78901',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-78901',
        target: 'account-78902',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-78901',
        target: 'instruction-78903',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-78901',
        target: 'instruction-78904',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-78901',
        target: 'instruction-78905',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { nftMarketplaceProject };