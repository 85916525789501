import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const vestingProgramProject: Project = {
  id: '',
  rootPath: '',
  name: 'Token Vesting Program',
  description: 'A Solana program that allows tokens to be distributed to users over a specified vesting period, ensuring they receive their tokens incrementally.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-33445',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'Vesting Program',
          item: {
            id: 'program-33445',
            type: 'program',
            name: 'Token Vesting Program',
            description: 'A program that enables incremental token distribution over a set period to users.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-33446',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'VestingAccount',
          item: {
            id: 'account-33446',
            type: 'account',
            name: 'VestingAccount',
            description: 'Stores information on the user’s token vesting, including the total tokens, amount vested, and the vesting schedule.',
            json: '{user: PubKey, total_tokens: u64, vested_amount: u64, release_schedule: Vec<i64>, status: string}',
            ownerProgramId: 'program-33445',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-33447',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'SetVestingSchedule',
          item: {
            id: 'instruction-33447',
            type: 'instruction',
            name: 'SetVestingSchedule',
            description: 'Sets the vesting schedule, specifying when tokens will be released to the user.',
            parameters: 'vesting_account: AccountInfo, release_schedule: Vec<i64>',
            aiInstruction: 'Define the vesting schedule by specifying the time intervals at which the user will receive their tokens. The total vesting period should be considered when creating this schedule.',
            ownerProgramId: 'program-33445',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-33448',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'ReleaseTokens',
          item: {
            id: 'instruction-33448',
            type: 'instruction',
            name: 'ReleaseTokens',
            description: 'Releases tokens to the user based on the vesting schedule.',
            parameters: 'vesting_account: AccountInfo',
            aiInstruction: 'Check the current time against the release schedule and release tokens to the user accordingly. Update the vested amount and mark tokens as fully vested once the schedule is complete.',
            ownerProgramId: 'program-33445',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-33449',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'CancelVesting',
          item: {
            id: 'instruction-33449',
            type: 'instruction',
            name: 'CancelVesting',
            description: 'Cancels the vesting program and reverts all unvested tokens back to the owner.',
            parameters: 'vesting_account: AccountInfo',
            aiInstruction: 'If the vesting program is cancelled, revert all unvested tokens back to the owner and mark the vesting account as cancelled.',
            ownerProgramId: 'program-33445',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-33445',
        target: 'account-33446',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-33445',
        target: 'instruction-33447',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-33445',
        target: 'instruction-33448',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-33445',
        target: 'instruction-33449',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { vestingProgramProject };