import { FileTreeItemType } from '../../interfaces/file';
import { CodeFile } from '../../contexts/CodeFileContext';
import { Project, ProjectDetails } from '../../interfaces/project';

const didVerificationProgramProject: Project = {
  id: '',
  rootPath: '',
  name: 'DID Verification Program',
  description: 'A Solana program that enables users to register a decentralized identity, verify credentials, and manage their identity data securely on the blockchain.',
  aiModel: '',
  apiKey: '',
  walletPublicKey: '',
  details: {
    nodes: [
      {
        width: 56,
        height: 44,
        id: 'program-44556',
        type: 'program',
        position: { x: 50, y: 200 },
        data: {
          label: 'DID Program',
          item: {
            id: 'program-44556',
            type: 'program',
            name: 'DID Verification Program',
            description: 'Enables users to register their decentralized identity and verify credentials securely on the blockchain.',
          },
        },
        selected: false,
        positionAbsolute: { x: 50, y: 200 },
      },
      {
        width: 80,
        height: 44,
        id: 'account-44557',
        type: 'account',
        position: { x: 300, y: 50 }, // Top-right
        data: {
          label: 'DIDAccount',
          item: {
            id: 'account-44557',
            type: 'account',
            name: 'DIDAccount',
            description: 'Stores a decentralized identity for a user, including the public key, credentials, and identity metadata.',
            json: '{user: PubKey, credentials: Vec<String>, metadata: String, status: string}',
            ownerProgramId: 'program-44556',
          },
        },
        selected: false,
        positionAbsolute: { x: 300, y: 50 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-44558',
        type: 'instruction',
        position: { x: 300, y: 150 }, // Second from top-right
        data: {
          label: 'RegisterDID',
          item: {
            id: 'instruction-44558',
            type: 'instruction',
            name: 'RegisterDID',
            description: 'Registers a new decentralized identity for the user.',
            parameters: 'did_account: AccountInfo, credentials: Vec<String>',
            aiInstruction: 'Create a new DID for the user, store their credentials and metadata, and set their status to "active". Ensure that the credentials are valid and verify the identity.',
            ownerProgramId: 'program-44556',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 150 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-44559',
        type: 'instruction',
        position: { x: 300, y: 250 }, // Third from top-right
        data: {
          label: 'VerifyDID',
          item: {
            id: 'instruction-44559',
            type: 'instruction',
            name: 'VerifyDID',
            description: 'Verifies the credentials of a user’s decentralized identity.',
            parameters: 'did_account: AccountInfo',
            aiInstruction: 'Verify the credentials stored in the DID account, ensuring that the identity is legitimate and not tampered with. If verified, the status is set to "verified".',
            ownerProgramId: 'program-44556',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 250 },
      },
      {
        width: 66,
        height: 44,
        id: 'instruction-44560',
        type: 'instruction',
        position: { x: 300, y: 350 }, // Bottom-right
        data: {
          label: 'UpdateDID',
          item: {
            id: 'instruction-44560',
            type: 'instruction',
            name: 'UpdateDID',
            description: 'Allows a user to update their decentralized identity credentials and metadata.',
            parameters: 'did_account: AccountInfo, new_credentials: Vec<String>, new_metadata: String',
            aiInstruction: 'Verify that the user is the owner of the DID account, update their credentials and metadata, and mark the account status as "updated". Ensure that the new credentials are valid.',
            ownerProgramId: 'program-44556',
          },
        },
        selected: true,
        positionAbsolute: { x: 300, y: 350 },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        source: 'program-44556',
        target: 'account-44557',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-2',
        source: 'program-44556',
        target: 'instruction-44558',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-3',
        source: 'program-44556',
        target: 'instruction-44559',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
      {
        id: 'edge-4',
        source: 'program-44556',
        target: 'instruction-44560',
        type: 'solana',
        animated: false,
        style: { stroke: '#ff0072', cursor: 'pointer', strokeWidth: 2 },
      },
    ],
    files: { name: '', type: 'directory', children: [] },
    codes: [],
    docs: [],
    isSaved: false,
    isAnchorInit: false,
    isCode: false,
    aiFilePaths: [],
    aiStructure: '',
    stateContent: '',
    uiResults: [],
    aiInstructions: [],
    sdkFunctions: [],
    buildStatus: false,
    deployStatus: false,
    isSdk: false,
    isUi: false,
    genUiClicked: false,
    idl: { fileName: '', content: '', parsed: { instructions: [], accounts: [] } },
    sdk: { fileName: '', content: '' },
    walletPublicKey: '',
    walletSecretKey: '',
    programId: null,
    pdas: [],
  } as ProjectDetails,
};

export { didVerificationProgramProject };